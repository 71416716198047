import { isPlatformServer } from '@angular/common';
import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    inject,
} from '@angular/core';

@Directive({
    selector: '[ebIntersection]',
})
export class EbIntersectionDirective implements OnInit, OnDestroy {
    private _platformId = inject(PLATFORM_ID);
    private _elementRef = inject(ElementRef);

    private _intersectionObserver!: IntersectionObserver;
    private _isIntersecting = false;

    @Input() ebDisabled = false;
    @Input() ebOnlyFirst = false;
    @Output() readonly intersecting = new EventEmitter<boolean>();

    public ngOnInit(): void {
        this._createIntersectionObservers();
    }

    public ngOnDestroy(): void {
        this._destroyIntersectionObservers();
    }

    private _createIntersectionObservers(): void {
        if (isPlatformServer(this._platformId)) {
            return;
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.15,
        };

        this._intersectionObserver = new IntersectionObserver((entries) => {
            const isIntersecting = entries.some((e) => e.isIntersecting);
            if (!this.ebDisabled && isIntersecting !== this._isIntersecting) {
                this._isIntersecting = isIntersecting;
                this.intersecting.next(isIntersecting);
                if (this.ebOnlyFirst && this._isIntersecting) {
                    this._destroyIntersectionObservers();
                }
            }
        }, options);

        this._intersectionObserver.observe(this._elementRef.nativeElement);
    }

    private _destroyIntersectionObservers(): void {
        if (this._intersectionObserver) {
            this._intersectionObserver.disconnect();
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ebCurrency',
})
export class EbCurrencyPipe implements PipeTransform {
    transform(value: number | undefined, currency: string | undefined): unknown {
        if (value === undefined || isNaN(+value) || value === null) {
            return '';
        } else if (currency) {
            return Intl.NumberFormat('pl-PL', { style: 'currency', currency }).format(value);
        } else {
            return Intl.NumberFormat('pl-PL', { style: 'decimal' }).format(value);
        }
    }
}

import { Component, ContentChild, EventEmitter, HostBinding, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'eb-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.less'],
})
export class EbTabComponent<D = void> {
    private _active = false;
    private _loaded = false;

    public get active(): boolean {
        return this._active;
    }

    public get loaded(): boolean {
        return this._loaded;
    }

    @Input() header: string | null = '';
    @Input() cache = true;
    @Input() data?: D;
    @Input() seoBot = false;

    @Output() readonly activated = new EventEmitter<void>();

    @HostBinding('class.eb-tab--deactive') protected get isDeactive(): boolean {
        return !this.active;
    }

    @ContentChild(TemplateRef) readonly contentTmpl: TemplateRef<any> | null = null;

    public activate(): void {
        this._active = true;
        this._loaded = true;
        this.activated.next();
    }

    public deactivate(): void {
        this._active = false;
    }
}

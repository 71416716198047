import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../apps/portal/src/environments/environment';

@Pipe({
    name: 'media',
})
export class EbMediaPipe implements PipeTransform {
    transform(value: string | null | undefined, ...args: unknown[]): unknown {
        return value ? environment.mediaUrl + value : ''; // zdjecia z webrock
        // return value ? environment.cdnUrl + value : ''; // zdjecia z CDN
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ebIsNumber',
})
export class EbIsNumberPipe implements PipeTransform {
    transform(value: number | null | undefined): boolean {
        return typeof value === 'number';
    }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IFreeSeats, IFreeSeatsRequest } from './models/free-seats.model';
import { TicketCategoriesResponse } from './models/ticket-categories-response.model';
import { TitleInfo } from './models/title-info.model';

@Injectable({
    providedIn: 'root',
})
export class TitleEventRestService {
    private readonly _httpClient = inject(HttpClient);

    private readonly _baseUrl = `${environment.portalApi}/Title`;
    private readonly _titleEventApi = `${environment.titleEventApi}/Event`;

    public getTitleInfo(titleEventId: number): Observable<TitleInfo> {
        return this._httpClient.get<TitleInfo>(`${this._baseUrl}/event/${titleEventId}`);
    }

    public getTicketCategories(titleEventId: number): Observable<TicketCategoriesResponse> {
        return this._httpClient.get<TicketCategoriesResponse>(`${this._titleEventApi}/${titleEventId}`);
    }

    public checkFreeSeats(request: IFreeSeatsRequest): Observable<IFreeSeats[]> {
        let params = new HttpParams({ fromObject: { eid: request.eventIds } });
        let headers = new HttpHeaders();

        if (request.partnerId) {
            params = params.append('partnerId', request.partnerId);
        }

        if (request.cacheLong) {
            headers = headers.append('Cache-Long', '1');
        }

        return this._httpClient.get<IFreeSeats[]>(`${this._baseUrl}/checkFreeSeats`, { params, headers });
    }
}

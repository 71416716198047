import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EbArrayToUrlStringPipe } from './array-to-url-string.pipe';
import { EbCategoryIconPipe } from './category-icon.pipe';
import { EbCurrencyPipe } from './currency.pipe';
import { EbDatePipe } from './date.pipe';
import { EbImgLazyLoadingDirective } from './img-lazy-loading.directive';
import { EbInnerHtmlDirective } from './inner-html.directive';
import { EbIntersectionDirective } from './intersect.directive';
import { EbIsBrowserDirective } from './is-browser.directive';
import { EbIsNumberPipe } from './is-number.pipe';
import { EbMediaPipe } from './media.pipe';
import { EbRepeatDirective } from './repeat.directive';
import { EbSafeHtmlPipe } from './safe-html.pipe';
import { EbSafeUrlPipe } from './safe-url.pipe';
import { EbEmptyArrayPipe } from './empty-array-pipe.pipe';
@NgModule({
    imports: [CommonModule],
    declarations: [
        EbDatePipe,
        EbCurrencyPipe,
        EbImgLazyLoadingDirective,
        EbRepeatDirective,
        EbIsNumberPipe,
        EbSafeUrlPipe,
        EbSafeHtmlPipe,
        EbCategoryIconPipe,
        EbInnerHtmlDirective,
        EbIntersectionDirective,
        EbIsBrowserDirective,
        EbArrayToUrlStringPipe,
        EbMediaPipe,
        EbEmptyArrayPipe,
    ],
    exports: [
        EbDatePipe,
        EbCurrencyPipe,
        EbIsNumberPipe,
        EbImgLazyLoadingDirective,
        EbRepeatDirective,
        EbSafeUrlPipe,
        EbSafeHtmlPipe,
        EbCategoryIconPipe,
        EbInnerHtmlDirective,
        EbIntersectionDirective,
        EbIsBrowserDirective,
        EbArrayToUrlStringPipe,
        EbMediaPipe,
        EbEmptyArrayPipe,
    ],
})
export class UiPipeModule {}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GtmService } from './gtm.service';

@NgModule({
    imports: [CommonModule],
})
export class GtmModule {
    static forRoot(): ModuleWithProviders<GtmModule> {
        return {
            ngModule: GtmModule,
            providers: [GtmService],
        };
    }
}

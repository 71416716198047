export class ArrayHelper {
    static flatMap<T, R>(items: T[], mapFn: (item: T) => R[]): R[] {
        const mapped = items.map(mapFn);
        const result: R[] = [];

        return result.concat(...mapped);
    }

    static sum<T>(array: T[], fieldGetter: (item: T) => number): number {
        return array.reduce((total, item) => total + fieldGetter(item), 0);
    }

    static groupBy<T, K extends string | number>(array: T[], fieldGetter: (item: T) => K): GroupedArray<T, K> {
        return Object.entries(
            array.reduce(
                (groupedArray, item) => {
                    const field = fieldGetter(item);
                    if (groupedArray[field]) {
                        groupedArray[field].push(item);
                    } else {
                        groupedArray[field] = [item];
                    }
                    return groupedArray;
                },
                {} as Record<K, T[]>,
            ),
        ).map(([field, items]) => ({ field, items }) as GroupEntry<T, K>);
    }
}

export type GroupEntry<T, K> = { field: K; items: T[] };

export type GroupedArray<T, K> = GroupEntry<T, K>[];

import { Pipe, PipeTransform } from '@angular/core';
import { SvgIcons } from '@ngneat/svg-icon';
import { CategoryIcon } from 'apps/portal/src/app/rest-api/models/category.model';

@Pipe({ name: 'ebCategoryIcon' })
export class EbCategoryIconPipe implements PipeTransform {
    private _categoryIcons = {
        [CategoryIcon.GUITAR]: 'guitar',
        [CategoryIcon.MASK]: 'mask',
        [CategoryIcon.MUSICAL_NOTES]: 'musical-notes',
        [CategoryIcon.CIRCUS]: 'circus',
        [CategoryIcon.CAMERA]: 'camera',
        [CategoryIcon.BALLOONS]: 'balloons',
        [CategoryIcon.CUP]: 'cup',
        [CategoryIcon.BRIEFCASE]: 'briefcase',
        [CategoryIcon.IMAGE]: 'image',
        [CategoryIcon.CASH]: 'cash',
        [CategoryIcon.TICKET]: 'ticket',
        [CategoryIcon.STAR]: 'star',
        [CategoryIcon.TREE]: 'tree',
        [CategoryIcon.MICROPHONE]: 'microphone',
        [CategoryIcon.TEDDY_BEAR]: 'teddy-bear',
        [CategoryIcon.DOUBLE_HEART]: 'double-heart',
        [CategoryIcon.SECURITY]: 'security',
    };

    private _categoryIcons48 = {
        [CategoryIcon.GUITAR]: 'guitar_48',
        [CategoryIcon.MASK]: 'mask_48',
        [CategoryIcon.MUSICAL_NOTES]: 'musical-notes_48',
        [CategoryIcon.CIRCUS]: 'circus_48',
        [CategoryIcon.CAMERA]: 'camera_48',
        [CategoryIcon.BALLOONS]: 'balloons_48',
        [CategoryIcon.CUP]: 'cup_48',
        [CategoryIcon.BRIEFCASE]: 'briefcase_48',
        [CategoryIcon.IMAGE]: 'image_48',
        [CategoryIcon.CASH]: 'cash_48',
        [CategoryIcon.TICKET]: 'ticket_48',
        [CategoryIcon.STAR]: 'star_48',
        [CategoryIcon.TREE]: 'tree_48',
        [CategoryIcon.MICROPHONE]: 'microphone_48',
        [CategoryIcon.TEDDY_BEAR]: 'teddy-bear_48',
        [CategoryIcon.DOUBLE_HEART]: 'double-heart_48',
        [CategoryIcon.SECURITY]: 'security_48',
    };

    transform(value: CategoryIcon, hasBiggerIcon = false): SvgIcons {
        return (hasBiggerIcon ? this._categoryIcons48[value] : this._categoryIcons[value]) as SvgIcons;
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import { EbTabComponent } from './tab/tab.component';
import { EbTabsetComponent } from './tabset/tabset.component';

@NgModule({
    imports: [CommonModule, SvgIconComponent, TranslocoModule],
    declarations: [EbTabsetComponent, EbTabComponent],
    exports: [EbTabsetComponent, EbTabComponent, SvgIconComponent],
})
export class UiTabsModule {}

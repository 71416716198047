import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notEmptyArray',
})
export class EbEmptyArrayPipe<T> implements PipeTransform {
    transform(value: T[] | null): boolean {
        return Array.isArray(value) && value.length !== 0;
    }
}

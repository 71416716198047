<div class="eb-tabset">
  <div class="eb-tabset__nav scroll-hide flex gap-5">
    <div
      *ngFor="let tab of tabs"
      (click)="activate(tab, true)"
      class="eb-tabset__header py-3 white-space-nowrap"
      [ngClass]="{ 'eb-tabset__header--active': tab.active, 'cursor-pointer': !tab.active }"
    >
      {{ tab.header }}
    </div>
  </div>
  <div class="eb-tabset__content" [ngClass]="contentStyle">
    <ng-container *ngIf="!isLoading || !showLoader; else loadingLabel">
      <ng-content />
    </ng-container>
    <ng-template #loadingLabel>
      <div class="eb-tabset__loader flex justify-content-center m-5">
        {{ 'libs.tabset.loading' | transloco }} <svg-icon class="ml-2" key="loading" />
      </div>
    </ng-template>
  </div>
</div>

import { isPlatformServer } from '@angular/common';
import { Directive, ElementRef, PLATFORM_ID, inject } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'img:not(.eager-load)' })
export class EbImgLazyLoadingDirective {
    private _platformId = inject(PLATFORM_ID);

    constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
        const supports = 'loading' in HTMLImageElement.prototype;

        if (supports || isPlatformServer(this._platformId)) {
            nativeElement.setAttribute('loading', 'lazy');
        } else {
            // fallback to IntersectionObserver
        }
    }
}

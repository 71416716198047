export class RandomNumberHelper {
    // działa tylko dla int-ów
    static random(max: number | undefined | null): number {
        if (!max) {
            return 0;
        }

        return Math.floor(Math.random() * (max + 1));
    }
}

import { isPlatformBrowser } from '@angular/common';
import { Directive, OnInit, PLATFORM_ID, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFrame } from '../../../../apps/portal/src/app/rest-api/models/iframe';
import { IframeService } from '../../../../apps/portal/src/app/services/iframe.service';

@Directive({
    selector: '[ebIsBrowser]',
})
export class EbIsBrowserDirective implements OnInit {
    private readonly _tpl = inject(TemplateRef<any>);
    private readonly _vcr = inject(ViewContainerRef);
    private readonly _iframeService = inject(IframeService);
    private readonly _platformId = inject(PLATFORM_ID);

    _iframe: IFrame | undefined;
    constructor() {
        this._iframeService.iframe$.pipe(takeUntilDestroyed()).subscribe((iframe: IFrame | undefined) => {
            this._iframe = iframe;
        });
    }

    public ngOnInit(): void {
        if (!this._iframe && isPlatformBrowser(this._platformId)) {
            this._vcr.createEmbeddedView(this._tpl);
        } else {
            this._vcr.clear();
        }
    }
}

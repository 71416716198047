import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef, inject } from '@angular/core';

@Directive({
    selector: '[ebRepeat]',
})
export class EbRepeatDirective implements OnChanges {
    private readonly _templateRef = inject(TemplateRef<any>);
    private readonly _viewContainerRef = inject(ViewContainerRef);

    @Input('ebRepeat') repeat = 1;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['repeat']) {
            this._viewContainerRef.clear();
            Array.from({ length: this.repeat }).forEach(() => {
                this._viewContainerRef.createEmbeddedView(this._templateRef);
            });
        }
    }
}

import { Article } from './article.interface';
import { City } from './province.model';

export interface Category {
    id: number;
    categoryName: string;
    slug: string;
    icon: CategoryIcon;
    subcategory: Category[];
    parentId: number;
    titlesOnTime: ITitleOnTime[];
    mostPopularCities: IMostPopularCity[];
    articles: Article[];
}

export enum CategoryIcon {
    'GUITAR' = 1,
    'MASK' = 2,
    'MUSICAL_NOTES' = 3,
    'CIRCUS' = 4,
    'CAMERA' = 5,
    'BALLOONS' = 6,
    'CUP' = 7,
    'BRIEFCASE' = 8,
    'IMAGE' = 9,
    'CASH' = 10,
    'TICKET' = 11,
    'STAR' = 12,
    'TREE' = 13,
    'MICROPHONE' = 14,
    'TEDDY_BEAR' = 15,
    'DOUBLE_HEART' = 16,
    'SECURITY' = 17,
}

export interface ITitleOnTime {
    id: string;
    title: string;
    slug: string;
    category: string;
    categorySlug: string;
    subcategory: string;
    subcategorySlug: string;
    imageLandscape: string;
    linkTo?: string;
    manualOrder: boolean;
    index?: number;
    promotionName?: string;
}

export interface IMostPopularCity extends City {
    province: string;
    performanceQuantity: number;
}

import { DateString } from './date-string.type';
import { ISeoBoxLinks, ISeoMeta } from './seo-info.model';

export interface TitlePage {
    id: string;
    title: string;
    imageLandscape: string;
    imagePortrait: string;
    description: string;
    slug: string;
    eventsDefaultPresentationType: TitleEventsPresentationTypes;
    category: TitleCategory;
    gallery: TitleGallery;
    places: Place[];
    artists: Artist[];
    properties: TitleProperty[];
    tags: string[];
    seo: ISeoMeta;
    seoBoxLinks: ISeoBoxLinks[];
    hearts?: number;
    extendedDescription: string;
    eventsDateFrom?: string;
    eventsDateTo?: string;
    organizerSlug: string;
    branding?: IBranding;
    organizer: ITitleOrganizer;
    showTicketListFrom: string | null;
    isPromotion?: boolean;
    loanInformationModuleEnabled?: boolean;
    isEventDateHidden: boolean;
}

export interface ITitleOrganizer {
    id: string;
    slug: string;
    name: string;
}

export interface IBranding {
    partnerId?: string;
    partnerName?: string;
    headerImage?: string;
    headerImageLink?: string;
    headerColor?: string;
    footerImage?: string;
    footerImageLink?: string;
    footerLinks?: IBrandingFooterLink[];
    footerColor?: string;
    buyTicketColor?: string;
    buyTicketFontColor?: string;
    isAffiliate?: boolean;
}

export interface ICarouselBrandingData {
    listName?: string;
    index?: number;
    promotionName?: string;
    promotionId?: string;
}

export interface BrandingCookieModel {
    titleId: string | undefined;
    eventId: string;
    affiliantName?: string;
    listName?: string;
    listPosition?: string;
    listId?: string;
    promotionName?: string;
    promotionId?: string;
}

export interface IBrandingFooterLink {
    anchor?: string;
    url?: string;
}

export interface TitleProperty {
    name: string;
    iconUrl: number;
}

export interface TitleCategory {
    id: number;
    name: string;
    slug: string;
    subcategory?: TitleCategory;
}

export interface TitleOrganizer {
    id: string;
    slug: string;
    name?: string;
}

export interface TitleEvent {
    id: string;
    titleId: string;
    date: DateString;
    customDateText: string | null;
    lastPlaces: boolean;
    currentlyUnavailable: boolean;
    soldOut: boolean;
    city: string;
    placeName: string;
    imageLandscape: string;
    imagePortrait: string;
    title: string;
    titleTitle?: string | null;
    webShopMaxDelay: number | null;
    sellDateFrom?: DateString;
    sellDateTo?: DateString;
    province?: string | null;
    street?: string;
    postalCode?: string;
    artists?: string[] | null;
    description?: string;
    organizerName?: string;
    organizerSlug?: string;
    isPromotion?: boolean;
    eventDateStatus?: EventDateStatus;
    uniqueId?: string;
    isTradedoublerEnabled?: boolean;
    partnerId?: string;
    organizerId?: string;
    isEventDateHidden?: boolean;
}

export interface TitleEventGroup {
    groupName: string;
    events: TitleEvent[];
}

export interface TitlePageEvents {
    groups: TitleEventGroup[];
    showDontMiss: boolean;
}
export interface Artist {
    id: string;
    name: string;
    slug: string;
    imageUrl: string;
    nearestEventDate?: Date | null;
}

export interface SimpleArtist {
    name: string;
    slug: string;
    image: string;
}

export interface Place {
    id?: string;
    name: string;
    slug: string;
    latitude?: number;
    longitude?: number;
    city: string;
    imageUrl: string;
}

export interface TitleGallery {
    items: TitleGalleryItem[];
}

export interface TitleGalleryItem {
    type: TitleGalleryItemTypes;
    url: string;
    row?: number | null;
    column?: number | null;
    rowSpan?: number | null;
    columnSpan?: number | null;
    largeUrl: string;
    smallUrl: string;
}

export enum TitleGalleryItemTypes {
    IMAGE = 1,
    VIDEO = 2,
    OTHER = 3,
}

export enum TitleEventsPresentationTypes {
    SINGLE = 1,
    MONTH = 2,
    DAY = 3,
}

export enum EventDateStatus {
    SaleAvailable = 0,
    BeforeSale = 1,
    AfterSale = 2,
}

import { TitleEvent } from './title-page.model';

export interface TitleEventAvailabilityData {
    titleEventAvailability: TitleEventAvailabilities;
    minPrice?: number;
    isPromoted?: boolean;
    freeSeatsCount?: string;
}

export interface ITitleEventAvailabilityRequest {
    partnerId?: string;
    events: TitleEvent[];
    cacheLong?: boolean;
}

export interface ISingleTitleEventAvailabilityRequest {
    partnerId?: string;
    event: TitleEvent;
    cacheLong?: boolean;
}

export enum TitleEventAvailabilities {
    UNDEFINED,
    SOLD_OUT,
    CURRENTLY_UNAVAILABLE,
    SELL_OPEN_SOON,
    SELL_ENDED,
    CHECK_FREE_SEATS,
    AVAILABLE,
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ebArrayToUrlString',
})
export class EbArrayToUrlStringPipe implements PipeTransform {
    transform(valueArray: string[]): string {
        return valueArray.join('/');
    }
}
